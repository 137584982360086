// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-blog-index-jsx": () => import("./../../../src/content/blog/index.jsx" /* webpackChunkName: "component---src-content-blog-index-jsx" */),
  "component---src-content-index-jsx": () => import("./../../../src/content/index.jsx" /* webpackChunkName: "component---src-content-index-jsx" */),
  "component---src-content-newsletter-jsx": () => import("./../../../src/content/newsletter.jsx" /* webpackChunkName: "component---src-content-newsletter-jsx" */),
  "component---src-content-projects-index-jsx": () => import("./../../../src/content/projects/index.jsx" /* webpackChunkName: "component---src-content-projects-index-jsx" */),
  "component---src-content-thanks-jsx": () => import("./../../../src/content/thanks.jsx" /* webpackChunkName: "component---src-content-thanks-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/PostTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-project-template-jsx": () => import("./../../../src/templates/ProjectTemplate.jsx" /* webpackChunkName: "component---src-templates-project-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../../../src/templates/TagTemplate.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */)
}

